import * as React from "react";
import * as game from "../model/game";
import {UserActionType} from "../model/userActions";
import Grid from "./Grid";
import Tetromino from "../model/Tetromino";
import Joystick from "./Joystick";

import "./Game.scss";
import Button from "./Button";



type GameProps =
{
    remSize: number,
    gameState: game.State,
    onGameStateUpdate: (updateFunction: (newGameState: game.State) => game.State) => void;
    onExitToMainMenu: () => void;
}



export default class Game extends React.PureComponent<GameProps, {}>
{

    private tickInterval: number | undefined = undefined;


    componentDidMount()
    {
        this.addKeyHandlers();
        this.tickInterval = window.setInterval(this.tick.bind(this), 1000/60);
    }


    componentWillUnmount()
    {
        window.clearInterval(this.tickInterval);
        this.removeKeyHandlers();
    }


    render()
    {
        const { gameState, remSize } = this.props;
        const { next, score } = gameState;
        const stack = game.lock(gameState).stack;

        const stackNext = next !== undefined ? Tetromino.byType[next].rotations[0].map(val => val ? next : undefined).toArray() : undefined;

        return (
            <div className="Game">
                <div className="Game__side-left" />
                <Grid data={stack} className="Game__grid" />
                <div className="Game__side-right">
                    <p>Next</p>
                    <div className="Game__next-frame">
                        {stackNext !== undefined ? <Grid data={stackNext} className="Game__next" /> : null}
                    </div>
                    <p>
                        Level: {score.level}
                    </p>
                    <p>
                        Score: {score.points}
                    </p>
                    <p>
                        Lines: {score.lines}
                    </p>
                    <p style={{position: "absolute", bottom: 0, marginBottom: "0.3rem"}}>
                        <Button onClick={this.handlePauseBtnClick} style={{width: "3rem"}}>PAUSE</Button>
                    </p>
                </div>
                <Joystick stepSize={remSize} onAction={this.handleJoystickAction} />
                {gameState.gameOver && (
                    <div className="Game__overlay Game__overlay--over">
                        <h4>G A M E &nbsp; O V E R</h4>
                        <p>Your Score: <br /><b>{score.points}</b></p>
                        <p><Button onClick={this.handleRestartClick}>RESTART</Button></p>
                        <p><Button onClick={this.handleMainMenuClick}>MAIN MENU</Button></p>
                    </div>
                )}
                {gameState.pause && (
                    <div className="Game__overlay Game__overlay--pause">
                        <h4>G A M E<br />P A U S E D</h4>
                        <p><Button onClick={this.handleResumeClick}>RESUME</Button></p>
                        <p><Button onClick={this.handleMainMenuClick}>MAIN MENU</Button></p>
                    </div>
                )}
            </div>
        );
    }


    tick() {
        // todo: get last tick time
        // todo: clear backup timer

        // requestAnimationFrame(this.tick.bind(this));
        // todo: set backup timer

        this.props.onGameStateUpdate(state => {
            let result = state;
            if (this.joystickActionReset !== undefined) {
                result = game.userAction(result, this.joystickActionReset, false);
                this.joystickActionReset = undefined;
            } else {
                const action = this.joystickActions.shift();
                if (action !== undefined) {
                    result = game.userAction(result, action, true);
                    this.joystickActionReset = action;
                }
            }
            return game.tick(result);
        });
    }


    private addKeyHandlers()
    {
        window.addEventListener("keydown", this.handleKeyEvent, false);
        window.addEventListener("keyup", this.handleKeyEvent, false);
    }


    private removeKeyHandlers()
    {
        window.removeEventListener("keydown", this.handleKeyEvent, false);
        window.removeEventListener("keyup", this.handleKeyEvent, false);
    }


    private handleKeyEvent = (evt: WindowEventMap["keydown" | "keyup"]) => {
        if (evt.repeat) {
            return;
        }
        const activate = (evt.type === "keydown");
        let userAction = keysToUserActions[evt.key];
        if (userAction === undefined) {
            return;
        }

        evt.preventDefault();
        this.props.onGameStateUpdate(state => game.userAction(state, userAction, activate));
    };


    private handleJoystickAction = (action: UserActionType) => {
        this.joystickActions.push(action);
    };


    private joystickActions: UserActionType[] = [];
    private joystickActionReset: UserActionType | undefined;



    private handleMainMenuClick = () =>
    {
        this.props.onExitToMainMenu();
    };


    private handleRestartClick = () =>
    {
        this.props.onGameStateUpdate(state => game.restart(state));
    };


    private handlePauseBtnClick = () =>
    {
        this.props.onGameStateUpdate(state => game.pause(state, true));
    };


    private handleResumeClick = () =>
    {
        this.props.onGameStateUpdate(state => game.pause(state, false));
    }

}


const keysToUserActions: {[key: string]: UserActionType} = {
    "ArrowUp":  "ROTATE_RIGHT",
    "ArrowLeft": "MOVE_LEFT",
    "ArrowRight": "MOVE_RIGHT",
    "ArrowDown": "SOFT_DROP",
    " ": "HARD_DROP",
};