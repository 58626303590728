export default class Matrix<T> {


    private readonly values: T[][];


    constructor(values: T[][])
    {
        this.values = values;
    }


    getRows(): number
    {
        return this.values.length;
    }


    getCols(): number
    {
        return this.values.length > 0 ? this.values[0].length : 0;
    }


    get(rowIndex: number, colIndex: number): T
    {
        return this.values[rowIndex][colIndex];
    }


    map<T2>(callbackFn: (val: T) => T2): Matrix<T2> {
        const newValues = this.values.map(row => row.map(callbackFn))
        return new Matrix(newValues);
    }

    toArray(): T[][] {
        return this.values.map(row => row.slice(0));
    }


    rotateRight(): Matrix<T>
    {
        const rows = this.values.length;
        const cols = rows > 0 ? this.values[0].length : 0;

        const rotated = Array(cols);

        for (let j = 0; j < cols; j++) {
            rotated[j] = Array(rows);
            for (let i = 0; i < rows; i++) {
                rotated[j][i] = this.values[rows - i - 1][j];
            }
        }
        return new Matrix<T>(rotated);
    }


    static create<T>(rows: number, cols: number, val: T): Matrix<T>
    {
        const values = Array(rows);
        for (let i = 0; i < rows; i++) {
            values[i] = Array(cols);
            for (let j = 0; j < cols; j++) {
                values[i][j] = val;
            }
        }
        return new Matrix(values);
    }

}


// 0,0 ... 0,n
// .
// .
// m,0 ... m,n


// abcd
// efgh

// dh
// cg
// bf
// ae

