const initialUserActions = {
    "ROTATE_RIGHT": false,
    "ROTATE_LEFT": false,
    "MOVE_LEFT": false,
    "MOVE_RIGHT": false,
    "SOFT_DROP": false,
    "HARD_DROP": false,
};


export type State = typeof initialUserActions;
export type UserActionType = keyof State;


export function init(): State {
    return initialUserActions;
}


export function apply(state: State, action: UserActionType, activate: boolean) {

    if (state[action] === activate) {
        return state;
    }

    return {...state, [action]: activate};
}