import * as React from "react";
import * as mainMenu from "../model/mainMenu";

import "./MainMenu.scss";



type MainMenuProps =
{
    state: mainMenu.State,
    onStateUpdate: (updater: (prevState: mainMenu.State) => mainMenu.State) => void,
    showExit: boolean,
    onStartGame: () => void,
    onExit: () => void,
}


export default function MainMenu({
    state, onStateUpdate,
    showExit,
    onStartGame,
    onExit,
}: MainMenuProps)
{
    const { startingLevel } = state;

    const onStartingLevelChange = (newStartingLevel: number) =>
        onStateUpdate(prevState => mainMenu.setStartingLevel(prevState, newStartingLevel));

    return MainMenuPure({
        startingLevel,
        showExit,
        onStartingLevelChange,
        onStartGame,
        onExit,
    });
}



type MainMenuPureProps =
{
    startingLevel: number,
    showExit: boolean,
    onStartingLevelChange: (startingLevel: number) => void,
    onStartGame: () => void,
    onExit: () => void,
};



function MainMenuPure({
    startingLevel,
    onStartingLevelChange,
    onStartGame,
    showExit,
    onExit,
}: MainMenuPureProps)
{
    return (
        <div className="MainMenu">
            <h1 className="MainMenu__title">R E T R I S</h1>

            <p className="MainMenu__starting-level">
                <button onClick={() => onStartingLevelChange(startingLevel - 1)}>⮜</button>
                <span>Starting level: <b>{startingLevel}</b></span>
                <button onClick={() => onStartingLevelChange(startingLevel + 1)}>⮞</button>
            </p>

            <p className="MainMenu__start">
                <button onClick={onStartGame}>START GAME</button>
            </p>

            {showExit && (
                <p className="MainMenu__exit">
                    <button onClick={onExit}>EXIT</button>
                </p>
            )}
        </div>
    );
}
