import * as game from "./game";
import * as mainMenu from "./mainMenu";


export type AppState =
{
    screen: Screen,
    highscore: {
        points: number,
        level: number,
        lines: number,
    },
    remSize: number | undefined,
    startedAsApp: boolean,
}


type Screen = MenuScreen | GameScreen;


type MenuScreen =
{
    type: "mainMenu",
    state: mainMenu.State;
}


type GameScreen =
{
    type: "game",
    state: game.State,
}


export function init(): AppState
{
    return {
        screen: { type: "mainMenu", state: mainMenu.init() },
        highscore: { level: 0, lines: 0, points: 0 },
        remSize: undefined,
        startedAsApp: true,
    };
}


export function setRemSize(app: AppState, remSize: number): AppState
{
    return (remSize === app.remSize) ? app : { ...app, remSize };
}
