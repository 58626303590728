import React, {CSSProperties} from 'react';
import './Grid.scss';


interface Props
{
    data: (string|undefined)[][]
    style?: CSSProperties
    className?: string
}


const Grid = ({data, style, className}: Props) => (
        <div className={"Grid" + (className ? " " + className : "")} style={style}>
            {data.map((cells, rowIndex) => (
                <div key={rowIndex} className="Grid__row">
                    {cells.map((cell, cellIndex) => (
                        <div
                            key={cellIndex}
                            className={"Grid__cell" + (cell ? " Grid__cell--" + cell : " Grid__cell--empty")}
                        />
                    ))}
                </div>
            ))}
        </div>
);

export default Grid;