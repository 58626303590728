import Tetromino, { TetrominoType } from "./Tetromino";



export type BagState = TetrominoType[];


export function init(): BagState
{
    return Object.keys(Tetromino.byType) as BagState;
}


export function random(bag: BagState): { bag: BagState, item: TetrominoType }
{
    const index = Math.floor(Math.random() * bag.length);
    const item = bag[index];

    let newBag = bag.slice(0);
    newBag.splice(index, 1);
    if (newBag.length === 0) {
        newBag = init();
    }
    return { bag: newBag, item };
}
