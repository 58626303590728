import * as React from "react";


import "./Joystick.scss";
import {UserActionType} from "../model/userActions";


type JoystickProps =
{
    stepSize: number,
    onAction: (action: UserActionType) => void,
};


type ActiveTouchState = {
    active: true,
    touchId: number;
    square: { x: number, y: number },
    pos: { x: number, y: number, t: number },
    continuousFrom: { x: number, y: number, t: number },
    continuousAction: UserActionType | undefined,
    moved: boolean,
};

type InactiveTouchState = { active: false };
type TouchState = ActiveTouchState | InactiveTouchState;



const initialState: TouchState = { active: false };



export default class Joystick extends React.PureComponent<JoystickProps>
{

    private touchState: TouchState = initialState;


    public render()
    {
        return null;
    }


    public componentDidMount(): void
    {
        window.addEventListener('touchstart', this.handleTouchStart, { passive: false });
        window.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        window.addEventListener('touchcancel', this.handleTouchCancel, { passive: false });
        window.addEventListener('touchend', this.handleTouchEnd, { passive: false });
    }


    public componentWillUnmount(): void
    {
        window.removeEventListener('touchstart', this.handleTouchStart);
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchcancel', this.handleTouchCancel);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }


    private handleTouchStart = (evt: TouchEvent) =>
    {
        const { target } = evt;
        if (target && (target as any).classList.contains('Button')) {
            console.log('returned');
            return;
        }

        evt.preventDefault();

        if (this.touchState.active) {
            return;
        }

        const { stepSize } = this.props;
        const { timeStamp: t, changedTouches } = evt;
        const { identifier: touchId, pageX: x, pageY: y} = changedTouches[0];

        const pos = { x, y, t };
        const continuousFrom = pos;
        const square = { x: x - stepSize/2, y: y - stepSize/2 };
        const moved = false;

        this.touchState = {
            active: true,
            touchId,
            pos,
            square,
            continuousFrom,
            continuousAction: undefined,
            moved,
        };
    };


    private handleTouchMove = (evt: TouchEvent) =>
    {
        if (!this.touchState.active) {
            return;
        }

        const { stepSize } = this.props;
        const { touchId, pos, square, moved, continuousAction, continuousFrom } = this.touchState;

        const touch = touchById(evt.changedTouches, touchId);
        if (!touch) {
            return;
        }

        evt.preventDefault();

        const { timeStamp: t} = evt;
        const { pageX: x, pageY: y } = touch;
        const dx = x - pos.x;
        const dy = y - pos.y;
        const adx = Math.abs(dx);
        const ady = Math.abs(dy);

        const maxRatio = 0.57; // 30 deg
        const newPos: ActiveTouchState["pos"] = { x, y, t };
        let newSquare: typeof square = square;
        let newMoved = moved;

        let action: UserActionType | undefined = undefined;
        let actionTimes: number = 0;
        let newContinuousAction = continuousAction;
        let newContinuousFrom = continuousFrom;
        if (adx > ady && ady / adx <= maxRatio) {
            const steps = Math.floor((x - square.x) / stepSize);
            newSquare = { x: square.x + steps * stepSize, y: y - stepSize / 2 };
            action = (steps > 0) ? "MOVE_RIGHT" : "MOVE_LEFT";
            actionTimes = Math.abs(steps);
            newMoved = true;

        } else if (ady > adx && adx / ady <= maxRatio) {
            const steps = Math.floor((y - square.y) / stepSize);
            newSquare = { x: x - stepSize / 2, y: square.y + steps * stepSize };
            // if last move wasnt down,
            if (steps > 0) {
                action = "SOFT_DROP";
                actionTimes = steps;
                newMoved = true;
            }
        }

        if (action !== undefined) {
            newContinuousAction = action;
            if (continuousAction !== undefined && continuousAction !== action) {
                newContinuousFrom = pos;
            }
        }

        if (action === "SOFT_DROP") {
            const { y: fromY, t: fromT } = continuousFrom;
            const { y: toY, t: toT } = newPos;
            const dt = toT - fromT;
            const dy = toY - fromY;
            if (dt < 100 && dy > stepSize * 1.5) {
                this.trigger("HARD_DROP");
                this.touchState = { active: false };
                return;
            }
        }

        if (action !== undefined) {
            this.trigger(action, actionTimes);
        }

        const update: Partial<TouchState> = {
            pos: newPos,
            square: newSquare,
            moved: newMoved,
            continuousFrom: newContinuousFrom,
            continuousAction: newContinuousAction,
        };
        Object.assign(this.touchState, update);
    };


    private handleTouchCancel = (evt: TouchEvent) =>
    {
        // evt.preventDefault();
        // const touch = this.touchState.active && touchById(evt.changedTouches, this.touchState.touchId);
        // if (!touch) {
        //     return;
        // }
        // this.touchState = { active: false };
        this.handleTouchEnd(evt);
    };


    private handleTouchEnd = (evt: TouchEvent) =>
    {
        this.handleTouchMove(evt);
        const { stepSize } = this.props;
        const touch = this.touchState.active && touchById(evt.changedTouches, this.touchState.touchId);
        if (!this.touchState.active || !touch) {
            return;
        }
        if (!this.touchState.moved) {
            const { y: fromY, t: fromT } = this.touchState.continuousFrom;
            const { y: toY, t: toT } = this.touchState.pos;
            const dy = toY - fromY;
            const dt = toT - fromT;
            if (dt < 500) {
                const action: UserActionType = (dy < -stepSize) ? "ROTATE_LEFT" : "ROTATE_RIGHT";
                this.trigger(action);
            }
        }

        this.touchState = { active: false };
    };


    private trigger(action: UserActionType, times: number = 1): void
    {
        for (let i = 0; i < times; i++) {
            this.props.onAction(action);
        }
    }
}

function touchById(touches: TouchList, identifier: number): Touch|undefined
{
    for (let i = 0; i < touches.length; i++) {
        const touch = touches.item(i);
        if (touch && touch.identifier === identifier) {
            return touch;
        }
    }
    return undefined;
}
