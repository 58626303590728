import speedByLevel from "./speedByLevel";



export type Score = {
    startingLevel: number,
    level: number,
    lines: number,
    points: number,
}


export function init(level: number = 0): Score {
    return { startingLevel: level, level, lines: 0, points: 0 };
}


export function evaluate(level: number, linesAtOnce: number): number {
    return (level + 1) * (
        linesAtOnce === 1 ? 40 :
        linesAtOnce === 2 ? 100 :
        linesAtOnce === 3 ? 300 :
        1200
    );
}


export function addLinesAtOnce(score: Score, linesAtOnce: number): Score
{
    let level = score.level;
    const points = score.points + evaluate(score.level, linesAtOnce);
    const lines = score.lines + linesAtOnce;

    level = Math.min(speedByLevel.length - 1, Math.max(level, Math.floor(lines / 10)));

    return { ...score, level, lines, points };
}
