import * as Bag from "./bag";
import * as CurrentTetronimo from "./currentTetronimoState";
import * as Stack from "./Stack";
import * as userActions from "./userActions";
import * as Score from "./Score";
import speedByLevel from "./speedByLevel";
import Tetromino, { TetrominoType } from "./Tetromino";



const SOFT_DROP_TICKS = 5;
const DAS_FIRST_TICKS = 10;
const DAS_NEXT_TICKS = 5;

const initialTicks = {
    current: 0,
    nextMove: undefined as number | undefined,
    nextSoftDrop: undefined as number | undefined,
    nextGravity: undefined as number | undefined,
    lastRotate: undefined as number | undefined,
    lastHardDrop: undefined as number | undefined,
};



export type State = {
    stack: Stack.Stack;
    current: CurrentTetronimo.CurrentTetronimoState,
    next: TetrominoType,
    bag: TetrominoType[],
    ticks: typeof initialTicks,
    actions: userActions.State,
    score: Score.Score,
    pause: boolean,
    gameOver: boolean,
}



export function create(level: number = 0, rows: number = 20, cols: number = 10): State
{
    let bag = Bag.init(), first: TetrominoType, next: TetrominoType;
    ({ bag, item: first } = Bag.random(bag));
    ({ bag, item: next } = Bag.random(bag));

    const current = CurrentTetronimo.spawn(first, cols);
    const nextGravity = initialTicks.current + speedByLevel[level];

    return {
        stack: Stack.init(rows, cols),
        actions: userActions.init(),
        score: Score.init(level),
        ticks: { ...initialTicks, nextGravity },
        current, next, bag,
        pause: false,
        gameOver: false,
    };
}



export function userAction(game: State, action: userActions.UserActionType, activate: boolean): State
{
    const actions = userActions.apply(game.actions, action, activate);
    return (actions === game.actions) ? game : { ...game, actions };
}



export function tick(game: State): State
{
    if (game.pause || game.gameOver) {
        return game;
    }

    const actions = game.actions;
    const gravityTicks = speedByLevel[game.score.level];
    const gravityTicksAfterMove = Math.max(gravityTicks, DAS_FIRST_TICKS);

    const currentTick = game.ticks.current + 1;
    let result: State = { ...game, ticks: { ...game.ticks, current: currentTick }};


    // rotation
    const lastRotateTicks = result.ticks.lastRotate;
    if (actions.ROTATE_LEFT !== actions.ROTATE_RIGHT) {
        if (lastRotateTicks === undefined) {
            const dr = actions.ROTATE_LEFT ? -1 : 1;
            result = handleRotate(result, dr, gravityTicksAfterMove);
        }
    } else if (lastRotateTicks !== undefined) {
        result = { ...result, ticks: { ...result.ticks, lastRotate: undefined }};
    }

    // movement
    if (actions.MOVE_LEFT !== actions.MOVE_RIGHT) {
        const dx = actions.MOVE_LEFT ? -1 : 1;
        const nextMove = result.ticks.nextMove;
        let dasTicks = (nextMove === undefined) ? DAS_FIRST_TICKS :
            (nextMove <= currentTick) ? DAS_NEXT_TICKS : undefined;
        if (dasTicks !== undefined) {
            result = handleMove(result, dx, dasTicks, gravityTicksAfterMove);
        }
    } else if (result.ticks.nextMove !== undefined) {
        result = {...result, ticks: {...result.ticks, nextMove: undefined}};
    }

    // soft drop
    const nextSoftDrop = result.ticks.nextSoftDrop;
    if (actions.SOFT_DROP) {
        const softDropTicks = Math.min(SOFT_DROP_TICKS, Math.round(gravityTicks / 2));
        if (nextSoftDrop === undefined || nextSoftDrop <= currentTick) {
            result = handleSoftDrop(result, softDropTicks, gravityTicks);
        }
    } else if (nextSoftDrop !== undefined) {
        result = { ...result, ticks: { ...result.ticks, nextSoftDrop: undefined }};
    }

    // hard drop
    const lastHardDrop = result.ticks.lastHardDrop;
    if (actions.HARD_DROP) {
        if (lastHardDrop === undefined) {
            result = handleHardDrop(result, gravityTicks);
        }
    } else if (lastHardDrop !== undefined) {
        result = { ...result, ticks: { ... result.ticks, lastHardDrop: undefined }};
    }

    // gravity & lock
    const nextGravity = result.ticks.nextGravity;
    if (nextGravity !== undefined && nextGravity <= currentTick) {
        result = handleGravity(result, gravityTicks);
    }

    return result;
}



export function pause(game: State, pause: boolean): State
{
    return (game.gameOver) ? game : { ...game, pause };
}



export function restart(game: State): State
{
    const rows = game.stack.length;
    const cols = game.stack[0].length;
    return create(game.score.startingLevel, rows, cols);
}



function handleRotate(game: State, dr: number, delayGravityTicks: number): State
{
    let result = rotate(game, dr);

    if (!isValid(result)) {
        return game;
    }

    const currentTicks = result.ticks.current;
    const lastRotate = currentTicks;
    const nextGravity = currentTicks + delayGravityTicks;

    return { ...result, ticks: { ...result.ticks, lastRotate, nextGravity }};
}



function handleMove(game: State, dx: number, dasTicks: number, delayGravityTicks: number): State
{
    let result = move(game, dx, 0);

    if (!isValid(result)) {
        return game;
    }

    const currentTicks = result.ticks.current;
    const nextMove = currentTicks + dasTicks;
    const nextGravity = currentTicks + delayGravityTicks;

    return { ...result, ticks: { ...result.ticks, nextMove, nextGravity }};
}



function handleSoftDrop(game: State, softDropTicks: number, delayGravityTicks: number): State
{
    const moved = move(game, 0, 1);

    if (!isValid(moved)) {
        return game;
    }

    const currentTicks = moved.ticks.current;
    const nextSoftDrop = currentTicks + softDropTicks;
    const nextGravity = currentTicks + delayGravityTicks;

    return { ...moved, ticks: { ...moved.ticks, nextSoftDrop, nextGravity }};
}



function handleHardDrop(game: State, delayGravityTicks: number): State
{
    let result: State = game;
    let moved: State;

    for (;;) {
        moved = move(result, 0, 1);
        if (isValid(moved)) {
            result = moved;
            continue;
        }
        break;
    }

    if (result !== game) {
        const currentTicks = result.ticks.current;
        const lastHardDrop = currentTicks;
        const nextGravity = currentTicks + delayGravityTicks;
        return { ...result, ticks: { ...result.ticks, nextGravity, lastHardDrop }};
    }
    return game;
}



function handleGravity(game: State, delayGravityTicks: number): State
{
    const moved = move(game, 0, 1);

    if (isValid(moved)) {
        const currentTicks = moved.ticks.current;
        const nextGravity = currentTicks + delayGravityTicks;
        return { ...moved, ticks: { ...moved.ticks, nextGravity }};
    }

    const locked = lock(game);
    const beforeSpawnNext = clearFullRowsAndUpdateScore(locked);
    let result = spawnNext(beforeSpawnNext);

    if (!isValid(result)) {
        result = { ...beforeSpawnNext, gameOver: true };
    }

    return result;
}



function move(game: State, dx: number, dy: number): State
{
    const { current } = game;
    if (!current) {
        throw new Error("Invalid state");
    }
    let { x, y } = current;
    return { ...game, current: { ...current, x: x + dx, y: y + dy }};
}


function rotate(game: State, dr: number): State
{
    const { current } = game;
    if (!current) {
        throw new Error("Invalid state");
    }
    let { rotation } = current;
    return { ...game, current: { ...current, rotation: (rotation + 4 + dr) % 4 }};
}


export function lock(game: State): State
{
    if (!game.current) {
        return game;
    }

    const { stack: s, current } = game;
    const { rotation, x, y } = current;
    const t = Tetromino.byType[current.tetromino].rotations[rotation];
    const tRows = t.getRows(), tCols = t.getCols();

    const newStack = Stack.clone(s);

    for (let i = 0; i < tRows; i++) {
        for (let j = 0; j < tCols; j++) {
            if (t.get(i, j)) {
                const gCol = x + j;
                const gRow = y + i;
                newStack[gRow][gCol] = current.tetromino;
            }
        }
    }
    return { ...game, stack: newStack, current: undefined, ticks: { ...game.ticks, nextGravity: undefined } };
}



function isValid(game: State): boolean {
    if (!game.current) {
        return true;
    }

    const { stack, current } = game;
    const { rotation, x, y } = current;
    const t = Tetromino.byType[current.tetromino].rotations[rotation];
    const tRows = t.getRows(), tCols = t.getCols();
    const sRows = stack.length, sCols = stack[0].length;

    for (let i = 0; i < tRows; i++) {
        for (let j = 0; j < tCols; j++) {
            if (t.get(i, j)) {
                const sCol = x + j;
                const sRow = y + i;

                if (sCol < 0 || sRow < 0 || sCol >= sCols || sRow >= sRows) {
                    // out of bounds
                    return false;
                }

                if (stack[sRow][sCol] !== undefined) {
                    // stack collision
                    return false;
                }
            }
        }
    }

    return true;
}



function clearFullRowsAndUpdateScore(game: State): State
{
    const fullRowsIndexes = Stack.getFullRowIndexes(game.stack);
    const linesCleared = fullRowsIndexes.length;

    if (linesCleared === 0) {
        return game;
    }

    const score = Score.addLinesAtOnce(game.score, linesCleared);
    const stack = Stack.cleanRows(game.stack, fullRowsIndexes);

    return { ...game, stack, score };
}


function spawnNext(game: State): State
{
    if (game.current) {
        throw new Error("Invalid state");
    }

    const current = CurrentTetronimo.spawn(game.next, game.stack[0].length);
    const { bag, item: next } = Bag.random(game.bag);

    const currentTicks = game.ticks.current;
    const nextGravity = currentTicks + speedByLevel[game.score.level];

    return { ...game, current, next, bag, ticks: { ...game.ticks, nextGravity } };
}

