import * as React from "react";
import Measure, {ContentRect} from "react-measure";



type MeasureRemProps = {
    onResize: (remPixels: number) => void;
}



export default class MeasureRem extends React.PureComponent<MeasureRemProps>
{
    render()
    {
        return (
            <Measure bounds onResize={this.handleResize}>
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        style={{position: "absolute", left: "-2rem", width: "1rem", height: "1rem"}}
                    />
                )}
            </Measure>
        );
    }


    private handleResize = (contentRect: ContentRect) =>
    {
        this.props.onResize.call(undefined, contentRect.bounds!.width);
    }
}