import Matrix from "../utils/Matrix";



export type TetrominoType = keyof typeof Tetromino["byType"]


export default class Tetromino
{

    readonly rotations: Matrix<number>[];


    constructor(shape: number[][])
    {
        this.rotations = [new Matrix(shape)];
        for (let i = 0; i < 3; i++) {
            this.rotations[i + 1] = this.rotations[i].rotateRight();
        }
    }


    static readonly byType = {
        "I": new Tetromino([[0, 0, 0, 0], [1, 1, 1, 1], [0, 0, 0, 0], [0, 0, 0, 0]]),
        "J": new Tetromino([[1, 0, 0], [1, 1, 1], [0, 0, 0]]),
        "L": new Tetromino([[0, 0, 1], [1, 1, 1], [0, 0, 0]]),
        "O": new Tetromino([[1, 1], [1, 1]]),
        "S": new Tetromino([[0, 1, 1], [1, 1, 0], [0, 0, 0]]),
        "T": new Tetromino([[0, 1, 0], [1, 1, 1], [0, 0, 0]]),
        "Z": new Tetromino([[1, 1, 0], [0, 1, 1], [0, 0, 0]]),
    };

}
