import Matrix from "../utils/Matrix";
import {TetrominoType} from "./Tetromino";



export type Stack = (TetrominoType|undefined)[][];


export function init(rows: number = 20, cols: number = 10): Stack
{
    return Matrix.create(rows, cols, undefined).toArray();
}


export function clone(stack: Stack): Stack
{
    return stack.map(row => row.map(col => col));
}


export function getFullRowIndexes(stack: Stack): number[]
{
    const result = [];
    for (let i = 0; i < stack.length; i++) {
        let isFull = true;
        for (let j = 0; j < stack[0].length; j++) {
            if (stack[i][j] === undefined) {
                isFull = false;
                break;
            }
        }
        if (isFull) {
            result.push(i);
        }
    }
    return result;
}


export function cleanRows(stack: Stack, rowIndexes: number[]): Stack
{
    let result = clone(stack);
    for (let rowIndex of rowIndexes) {
        result.splice(rowIndex, 1);
        result.unshift(result[0].map(_ => undefined));
    }
    return result;
}
