import Tetromino, { TetrominoType } from "./Tetromino";


export type CurrentTetronimoState = {
    tetromino: TetrominoType,
    x: number,
    y: number,
    rotation: number,
}|undefined;



export function spawn(tetromino: TetrominoType, stackCols: number): CurrentTetronimoState
{
    const rotation = 0;
    const tetrominoCols = Tetromino.byType[tetromino].rotations[rotation].getCols();
    const x = Math.floor((stackCols - tetrominoCols) / 2);
    const y = 0;

    return { tetromino, rotation, x, y };
}
