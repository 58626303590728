import "./Button.scss";
import * as React from "react";

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;



export default function Button(props: ButtonProps)
{
    const { className, children, ...rest } = props;
    const updatedClassName = 'Button ' + className;

    return (
        <button className={updatedClassName} {...rest}>{children}</button>
    );
}
