const MIN_STARTING_LEVEL = 0;
const MAX_STARTING_LEVEL = 20;



export type State =
{
    startingLevel: number,
}


export function init(): State
{
    return {
        startingLevel: 0,
    }
}


export function setStartingLevel(prevState: State, startingLevel: number)
{
    console.log('xx');
    startingLevel = Math.max(MIN_STARTING_LEVEL, Math.min(MAX_STARTING_LEVEL, startingLevel));
    return (startingLevel !== prevState.startingLevel) ?
        { ...prevState, startingLevel } : prevState;
}
