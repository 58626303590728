import * as React from 'react';
import * as app from "../model/app";
import * as game from "../model/game";
import * as mainMenu from "../model/mainMenu";
import Game from "./Game";
import MainMenu from "./MainMenu";
import MeasureRem from "./MeasureRem";

import './App.scss';



export default class App extends React.Component<{}, app.AppState>
{

    state = app.init();


    render()
    {
        return (
            <>
                <MeasureRem onResize={this.handleRemResize}/>
                {this.renderScreen()}
            </>
        );
    }


    private renderScreen()
    {
        const { remSize, screen, startedAsApp } = this.state;
        if (remSize === undefined) {
            return null;
        }

        if (screen.type === "mainMenu") {
            return (
                <MainMenu
                    state={screen.state}
                    onStateUpdate={this.handleMainMenuStateUpdate}
                    showExit={startedAsApp}
                    onStartGame={this.handleStartNewGame}
                    onExit={this.handleExit}
                />
            );
        }

        if (screen.type === "game") {
            return (
                <Game
                    gameState={screen.state}
                    onGameStateUpdate={this.handleGameStateUpdate}
                    onExitToMainMenu={this.handleGameExitToMainMenu}
                    remSize={remSize}
                />
            );
        }
    }


    private handleRemResize = (remSize: number) =>
    {
        this.setState(prevState => app.setRemSize(prevState, remSize));
    };


    private handleMainMenuStateUpdate = (updater: (state: mainMenu.State) => mainMenu.State) =>
    {
        this.setState(prevState => {
            if (prevState.screen.type !== "mainMenu") {
                return null;
            }
            const mainMenuState = prevState.screen.state;
            const newMainMenuState = updater(mainMenuState);
            if (newMainMenuState === mainMenuState) {
                return null;
            }
            return { screen: { type: "mainMenu", state: newMainMenuState }};
        });
    };


    private handleGameStateUpdate = (updater: (state: game.State) => game.State) =>
    {
        this.setState(prevState => {
            if (prevState.screen.type !== "game") {
                return null;
            }
            const prevGameState = prevState.screen.state;
            const newGameState = updater(prevGameState);
            if (newGameState === prevGameState) {
                return null;
            }
            return { screen: {type: "game", state: newGameState }};
        });
    };


    private handleGameExitToMainMenu = () =>
    {
        this.setState(prevState => {
            const { screen } = prevState;
            const startingLevel = (screen.type === "game") ? screen.state.score.startingLevel : 0;
            return {
                screen: {
                    type: "mainMenu",
                    state: { startingLevel },
                }
            }
        });
    };


    private handleStartNewGame = () =>
    {
        this.setState(prevState => ({
            screen: {
                type: "game",
                state: game.create(prevState.screen.type === "mainMenu" ? prevState.screen.state.startingLevel : 0),
            }
        }))
    };


    private handleExit = () =>
    {
        window.history.back();
    };

}